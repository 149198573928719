var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reserve-table-container px-0 mx-0"},[(_vm.modalShow)?_c('IodReportPopup',{attrs:{"modalShow":_vm.modalShow,"lensOrderId":_vm.lensOrderId,"reserved":_vm.reserved},on:{"update:modalShow":function($event){_vm.modalShow=$event},"update:modal-show":function($event){_vm.modalShow=$event}}}):_vm._e(),_c('b-table',_vm._g({staticClass:"table-list",attrs:{"responsive":"","fixed":"","no-local-sorting":"","id":"reserve-table","items":_vm.orders,"fields":_vm.fields,"sort-by":_vm.camelCase(_vm.queryParams.sortBy),"sort-desc":_vm.queryParams.sortOrder === 'desc'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"head(orderDate)",fn:function(data){return [_c('div',{staticClass:"right-light-border"},[_c('div',{staticClass:"d-flex column-explanation"},[_c('div',[_vm._v(" "+_vm._s(data.label)+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])])]}},{key:"head(createdBy)",fn:function(data){return [_c('div',{staticClass:"content"},[_c('div',{staticClass:"d-flex column-explanation"},[_c('div',[_vm._v(" "+_vm._s(data.label)+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(" "+_vm._s(_vm.t('patientNameLastFirst'))+" ")])])])])]}},{key:"cell(orderNumber)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',[_c('router-link',{staticClass:"record-link text-primary mb-1",attrs:{"to":{
                            name: _vm.isPreOrderListMode ? 'PreOrderDetails' : 'ReserveDetails',
                            params: {orderId: item.orderHeaderId.toString()},
                        }}},[_vm._v(" "+_vm._s(item.orderNumber)+" ")])],1)])]}},{key:"cell(ocosPatientId)",fn:function(ref){
                        var value = ref.value;
                        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                    name: 'PatientDetails',
                    params: {patientId: item.patientId},
                }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(opEye)",fn:function(ref){
                var value = ref.value;
return [_c('b-img',{staticClass:"pr-1",attrs:{"src":_vm.imageSrc(value)}})]}},{key:"cell(selectedPrescription)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_c('div',{staticClass:"three-line-min"},[_c('p',{staticClass:"text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0"},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( item.selectedModel, _vm.currentUser.decimalSeparator ))+" ")]),_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter(value, _vm.currentUser.decimalSeparator))+" ")])])]}},{key:"cell(reservedPrescriptionDetails)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-start"},[(
                        !!item.lensOrderId &&
                        _vm.hasReservationIodPermission &&
                        item.status != 'Canceled' &&
                        (item.selectedModel
                            ? item.selectedModel.toLowerCase().includes('toric')
                            : false)
                    )?_c('b-button',{staticClass:"btn-iod mr-2 mt-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.iodBtnClicked(item)}}},[_vm._v(" "+_vm._s(_vm.isSidButton(item))+" ")]):_vm._e(),_c('div',{staticClass:"three-line-min"},[_c('LensModel',{attrs:{"lensDescriptionDetails":value}}),_c('LensPrescription',{attrs:{"lensDescriptionDetails":value}})],1)],1)]}},{key:"cell(doctor)",fn:function(ref){
                    var value = ref.value;
return [_c('router-link',{attrs:{"to":{
                    name: 'SurgeonDetails',
                    params: {doctorId: value.doctorId},
                }}},[_vm._v(" "+_vm._s(value.ocosDoctorId)+" - "+_vm._s(value.firstName)+" "+_vm._s(value.lastName)+" ")])]}},{key:"cell(status)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_c('span',{class:{
                    'text-warning': _vm.isDistributorMode && value === _vm.OrderSubStatus.PREORDER,
                }},[_vm._v(" "+_vm._s(value))]),_c('br'),(
                    [
                        _vm.OrderSubStatus.RESERVED,
                        _vm.OrderSubStatus.IN_CART,
                        _vm.OrderSubStatus.PREORDER,
                        _vm.OrderSubStatus.PREORDER_RESERVATION ].includes(value)
                )?_c('p',{class:[
                    'text-caption light mb-0',
                    {
                        'text-danger': item.expirationDateInWarningRange,
                        'text-gray-dark': !item.expirationDateInWarningRange,
                    } ]},[(_vm.isConsignment(item))?_c('span',[_vm._v(_vm._s(_vm.t('invLookup_Consignment')))]):_c('span',{staticClass:"white-space-pre"},[_vm._v(_vm._s(_vm.t('short_expiration'))+" "+_vm._s(_vm._f("date")(item.expirationDateTime,{ isUTC: item.orderHeaderId == item.orderNumber, })))])]):_vm._e(),(value == _vm.OrderSubStatus.ORDERED)?_c('router-link',{staticClass:"record-link text-primary mb-1",attrs:{"to":{
                    name: 'OrderDetails',
                    params: {orderId: item.followUpOrderHeaderId},
                }}},[_vm._v(" "+_vm._s(item.followUpOrderHeaderId)+" ")]):_vm._e()]}},{key:"head(selected)",fn:function(ref){return [_c('div',{staticClass:"right-light-border"},[_c('div',{staticClass:"d-flex column-explanation no-wrap"},[_c('span',{staticClass:"align-self-center",attrs:{"for":"selected"}},[_vm._v(" "+_vm._s(_vm.t(_vm.isAllSelected ? 'unselectAll' : 'selectAll')))]),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"checked":_vm.isAllSelected,"disabled":!_vm.reserveItems.length,"name":"selected"},on:{"change":_vm.selectedChange}})],1)])]}},{key:"cell(selected)",fn:function(ref){
                var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"ml-2 text-center",attrs:{"disabled":!_vm.canAddToCart(item) || _vm.isConsignment(item)},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"cell(actions)",fn:function(ref){
                var item = ref.item;
return [_c('b-button-group',{attrs:{"size":"sm"}},[(_vm.canAddToCart(item) && !_vm.isConsignment(item))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"px-2",attrs:{"variant":"white","size":"sm","title":_vm.t('addToCart')},on:{"click":function($event){return _vm.addToCart(item)}}},[_c('b-icon',{attrs:{"icon":"cart-plus-fill","scale":"1.2","variant":"primary"}})],1):_vm._e(),(item.status == _vm.OrderSubStatus.IN_CART)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"px-2",attrs:{"variant":"white","size":"sm","title":_vm.t('removeFromCart')},on:{"click":function($event){return _vm.removeFromCart(item)}}},[_c('b-icon',{attrs:{"icon":"cart-dash-fill","scale":"1.2","variant":"warning"}})],1):_vm._e(),(_vm.canAddToCart(item))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"px-2",attrs:{"variant":"white","size":"sm","title":_vm.t('cancelReservation')},on:{"click":function($event){return _vm.promptCancel(item)}}},[_c('b-icon',{attrs:{"icon":"trash-fill","scale":"1.2","variant":"danger"}})],1):_vm._e()],1)]}}])},_vm.$listeners)),_c('LensPrompt',{attrs:{"show":_vm.showRemovePrompt,"lens":_vm.lens,"show-expiration-window":false,"removePromptKeyText":'reservationDetails_CancelReservationPrompt'},on:{"update:show":function($event){_vm.showRemovePrompt=$event},"on-remove":function($event){return _vm.cancel()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }